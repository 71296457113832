import type { AllCollections } from '~/data/collections';
import type { AllBrands } from '~/data/entities';

export const getFilteredList = <T extends AllBrands | AllCollections>(
	items: T[] | undefined,
	searchQuery: string | undefined,
	key: string,
): T[] => {
	if (!items) return [];
	if (searchQuery) {
		const normalizedQuery = searchQuery
			.replace(/[^a-zA-Z0-9]/g, '')
			.toLowerCase();
		return items.filter((item) => {
			const value = item[key as keyof T];
			if (typeof value === 'string') {
				return value
					.replace(/[^a-zA-Z0-9]/g, '')
					.toLowerCase()
					.includes(normalizedQuery);
			}
			return false;
		});
	} else {
		return items;
	}
};

export const getGroupByInitial = (
	items: AllBrands[] | AllCollections[],
	key: string,
): string[] => {
	const list = items
		.map((item) => {
			const value = item[key as keyof typeof item];
			return typeof value === 'string'
				? String(value).trim().charAt(0).toUpperCase()
				: '';
		})
		.filter(Boolean);
	const unique = [...new Set(list)];
	return unique.filter((item) => item !== '');
};

export const handleScroll = (value: string) => {
	const element = document.getElementById(value);
	if (element && isMobileView()) {
		const headerHeight = 155;
		const elementPosition =
			element.getBoundingClientRect().top + window.scrollY;
		const offsetPosition = elementPosition - headerHeight;
		window.scrollTo({
			top: offsetPosition,
		});
	} else {
		element?.scrollIntoView({
			block: 'start',
			inline: 'center',
		});
	}
};

export const sortInitials = (value: string[]): string[] => {
	return value.sort((a, b) => {
		const isANumber = !isNaN(Number(a));
		const isBNumber = !isNaN(Number(b));

		if (isANumber && !isBNumber) return -1;
		if (!isANumber && isBNumber) return 1;

		return a.localeCompare(b, undefined, { numeric: true });
	});
};
